import React, {useEffect} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { PrivacyCard } from "../components/PrivacyCard";
import { privacyPolicyText } from "./PrivacyPolicyText";
import { useGoogleAnalytics } from "../hooks/useGoogleAnalytics";
import classes from "../App.module.css";

export const PrivacyPolicy: React.FC = (): JSX.Element => {
  const { trackEvent, trackPageView } = useGoogleAnalytics();

  useEffect(() => {
    trackPageView();
  },[trackPageView]);

  const handlePrivacyPolicyPageLinkClick = () => {
    trackEvent('Link', 'Click', 'Email link on Privacy Policy page');
  }

  return (
    <header>
      <Container fluid>
        <Row>
          <div className={classes.cardContainer}>
            {Object.keys(privacyPolicyText).map((key, index) => {
              const { text, title } = privacyPolicyText[key];
              return (
                <Col xs={12} key={index}>
                  <PrivacyCard
                    fontSize={key === `card1` ? `40px` : `1.25rem`}
                    link={key === `card5` ? ` admin@bonzercare.com` : ``}
                    onClick={handlePrivacyPolicyPageLinkClick}
                    text={text}
                    textAlign={text && key === `card1` ? `center` : `left`}
                    title={title}
                  />
                </Col>
              );
            })}
          </div>
        </Row>
      </Container>
    </header>
  );
};
