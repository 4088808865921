import React from 'react';
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics';

interface GoogleAnalyticsProviderProps {
    children: React.ReactNode;
}

export const GoogleAnalyticsProvider: React.FC<GoogleAnalyticsProviderProps> = ({ children }) => {
    useGoogleAnalytics();

    return <>{children}</>;
};