interface Card {
    title: string;
    text: string;
};

interface HomeText {
    [key: string]: Card;
};


export const homeText: HomeText = {
    card1: {
        title: "About Us",
        text: 'Bonzer Care provides home health services in the convenience of a client’s home in King and Snohomish counties. Our mission is to provide quality care to all clients based on their individual needs, in a safe and respectful manner that upholds clients’ dignity.'
    },
    card2: {
        title: 'Our Passion',
        text: 'At Bonzer Care, we strive to render home health care services according to the highest medical and ethical standards. Our professionally trained staff are always at your service to ensure you get the personalized care you deserve. Your health and well-being is our primary concern.'
    },
    card3: {
        title: 'Services',
        text: 'As a home health agency, in accordance with the laws of Washington state, Bonzer Care will provide medical and non-medical services to ill, disabled, or vulnerable people with functional limitations, enabling them to maintain their highest level of independence in the comfort of their homes.'
    },
    card4: {
        title: 'Care Team',
        text: 'Our care team includes Nurses, Nurse Assistants, Social Workers, Physical and Occupational Therapists, Respiratory and Speech Therapists, Nutritionists / Dieticians and others. Together, we care for each client according to their care plan.'
    }
};