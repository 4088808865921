import { useEffect } from "react";
import ReactGA from "react-ga4";

const trackingId = "G-VEPNS5H8RE";

export const useGoogleAnalytics = () => {
  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);

  const trackPageView = (pagePath?: string) => {
    ReactGA.send({ hitType: "pageview", page: pagePath || window.location.pathname });
  };

  const trackEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number
  ) => {
    ReactGA.event({ category, action, label, value });
  };

  return { trackPageView, trackEvent };
};
