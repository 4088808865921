import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { homeText } from "./HomeText";
import { useGoogleAnalytics } from "../hooks/useGoogleAnalytics";
import classes from "../App.module.css";

const imageUrl = "/images/elderly-care-by-female-caregiver.jpg";

export const Home: React.FC = (): JSX.Element => {
  const { trackPageView } = useGoogleAnalytics();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <Card className="mb-3 border-0">
            <Card.Img
              src={imageUrl}
              className={`img-fluid ${classes.heroImage}`}
              alt="Hero image - female caregiver in squating position, helping an elderly man sitting on a bed put on his brown shoes"
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <div className={classes.cardContainer}>
          {Object.keys(homeText).map((key, index) => {
            const { text, title } = homeText[key];
            return (
              <Col xs={12} key={index}>
                <Card className="mb-3 border-0">
                  <Card.Body>
                    <Card.Title className={classes.cardTitle}>
                      {title}
                    </Card.Title>
                    <Card.Text>{text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </div>
      </Row>
    </Container>
  );
};
