import React from "react";
import { Footer } from "../components/UI/Footer";
import { Navbar } from "../components/UI/Navbar";
import { Outlet } from "react-router-dom";

export const Layout: React.FC = (): JSX.Element => (
  <>
    <Navbar />
    <main>
      <Outlet />
    </main>
    <Footer />
  </>
);
